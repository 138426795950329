
import gql from 'graphql-tag';

import apolloFetcher from './apollo-fetcher';
import { IMPERMAX_SUBGRAPH_URLS } from 'config/web3/subgraphs';
import { LendingPoolData } from 'types/interfaces';

const borrowableStr = `{
  id
  underlying {
    id
    symbol
    name
    decimals
    derivedUSD
  }
  totalBalance
  totalBorrows
  borrowRate
  reserveFactor
  kinkBorrowRate
  kinkUtilizationRate
  borrowIndex
  accrualTimestamp
  exchangeRate
  totalBalanceUSD
  totalSupplyUSD
  totalBorrowsUSD
  farmingPool {
    epochAmount
    epochBegin
    segmentLength
    vestingBegin
    sharePercentage
    distributor {
      id
    }
  }
}`;

// TODO: `1000` is hardcoded
const query = gql`{
  lendingPools(first: 1000, orderBy: totalBorrowsUSD, orderDirection: desc, where: {
      id_not_in:[
        "0x6ea0547ffa30bfb225414fc4e4dc9d5f0c520c3d",
        "0x58f876857a02d6762e0101bb5c46a8c1ed44dc16",
        "0x1303246855b5b5ebc71f049fdb607494e97218f8",
        "0x84392649eb0bc1c1532f2180e58bae4e1dabd8d6"
      ]}) {
    id
    borrowable0 ${borrowableStr}
    borrowable1 ${borrowableStr}
    collateral {
      id
      totalBalance
      totalBalanceUSD
      safetyMargin
      liquidationIncentive
      exchangeRate
    }
    pair {
      reserve0
      reserve1
      reserveUSD
      token0Price
      token1Price
      derivedUSD
      uniswapV2PairAddress
    }
  }
}`;

const getLendingPools = async (chainID: number): Promise<Array<LendingPoolData>> => {
  const impermaxSubgraphURL = IMPERMAX_SUBGRAPH_URLS[chainID];
  const result = await apolloFetcher(impermaxSubgraphURL, query);

  return result.data.lendingPools;
};

export type {
  LendingPoolData
};

export default getLendingPools;
