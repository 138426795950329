
const IS_STAKING_APP = false;

const UI_MARGIN = 1.1;
const DUST = 1.000001;

export {
  IS_STAKING_APP,
  UI_MARGIN,
  DUST
};
