
const IMPERMAX_WEBSITE_LINK = 'https://app.bombmax.fi';
const IMPERMAX_TWITTER_LINK = 'https://twitter.com/BombMoneyBSC';
const IMPERMAX_TELEGRAM_LINK = 'https://t.me/bombmoneybsc';
const IMPERMAX_DISCORD_LINK = 'https://discord.gg/VjBjf52q';
const IMPERMAX_MEDIUM_LINK = 'https://bombbshare.medium.com/';
// const IMPERMAX_REDDIT_LINK = 'https://www.reddit.com/r/ImpermaxFinance';
const IMPERMAX_GIT_HUB_LINK = 'https://github.com/bombmoney';
const IMPERMAX_YOU_TUBE_LINK = 'https://www.youtube.com/c/BombMoney';

export {
  IMPERMAX_TELEGRAM_LINK,
  IMPERMAX_TWITTER_LINK,
  IMPERMAX_WEBSITE_LINK,
  // IMPERMAX_REDDIT_LINK,
  IMPERMAX_DISCORD_LINK,
  IMPERMAX_YOU_TUBE_LINK,
  IMPERMAX_MEDIUM_LINK,
  IMPERMAX_GIT_HUB_LINK
};
