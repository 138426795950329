
import { CHAIN_IDS } from 'config/web3/chains';

const ROUTER_ADDRESSES: {
  [chainId: number]: string;
} = {
  // [CHAIN_IDS.ROPSTEN]: '0xbFf4acF789297A8507Eb7493AE18EB2C3A3A9632',
  // [CHAIN_IDS.KOVAN]: '0x0000000000000000000000000000000000000000',
  // [CHAIN_IDS.ETHEREUM_MAIN_NET]: '0x5e169082fff23cee6766062b96051a78c543127d'
  [CHAIN_IDS.BSC]: '0x6664afefe82625e70cd44a5fd04a0a137a6dea49'
};

export {
  ROUTER_ADDRESSES
};
