
import { CHAIN_IDS } from 'config/web3/chains';

const W_ETH_ADDRESSES: {
  [chainId: number]: string;
} = {
  // [CHAIN_IDS.ROPSTEN]: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
  // [CHAIN_IDS.KOVAN]: '0x0000000000000000000000000000000000000000',
  // [CHAIN_IDS.ETHEREUM_MAIN_NET]: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'
  [CHAIN_IDS.BSC]: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c'
};

export {
  W_ETH_ADDRESSES
};
